import { XMarkIcon } from '@heroicons/react/24/outline';
import * as Dialog from '@radix-ui/react-dialog';
import { Link } from '@remix-run/react';
import { AnimatePresence, motion } from 'framer-motion';
import * as React from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { Button } from '~/cds/Button';
import { cn } from '~/common/utils/styles';
import {
    NavAuthLinks,
    NavLinks,
    NavPortalLinks,
    type LinksType,
} from '../Navbar';

interface MobileMenuProps {
    userId: string | null;
    avalonOnboarding: string | boolean;
}

export const MobileMenu = ({ userId, avalonOnboarding }: MobileMenuProps) => {
    const [open, setOpen] = React.useState(false);
    const links: LinksType[] = userId ? NavPortalLinks : NavAuthLinks;

    return (
        <div className="justify-end xs:inline-block sm:hidden">
            <Dialog.Root onOpenChange={(o) => setOpen(o)}>
                <Dialog.Trigger asChild>
                    <div>
                        {open ? (
                            <XMarkIcon className="h-[24px] w-auto text-white" />
                        ) : (
                            <GiHamburgerMenu className="h-[24px] w-auto cursor-pointer text-white" />
                        )}
                    </div>
                </Dialog.Trigger>
                <AnimatePresence>
                    {open ? (
                        <Dialog.Portal forceMount>
                            <motion.nav
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.3 }}
                                className="fixed top-[88px] bottom-0 left-0 right-0 z-50"
                            >
                                <Dialog.Content
                                    forceMount
                                    className="z-50 h-full w-full flex-col justify-between bg-black outline-none xs:flex sm:hidden"
                                >
                                    <div className="flex flex-col items-start">
                                        {NavLinks.map((link, index) => (
                                            <Dialog.Close key={index} asChild>
                                                <Button
                                                    variant="primary"
                                                    size="lg"
                                                    as={Link}
                                                    to={link.href}
                                                    className={cn({
                                                        hidden:
                                                            link.name ===
                                                            'Home',
                                                    })}
                                                >
                                                    {link.name.toUpperCase()}
                                                </Button>
                                            </Dialog.Close>
                                        ))}
                                    </div>
                                    {avalonOnboarding && (
                                        <div
                                            className={cn('flex py-4', {
                                                'px-8': userId,
                                            })}
                                        >
                                            {links.map((link, index) => (
                                                <Dialog.Close
                                                    key={index}
                                                    asChild
                                                >
                                                    <Button
                                                        variant={
                                                            link.buttonVariant
                                                        }
                                                        size="lg"
                                                        as={Link}
                                                        to={link.href}
                                                    >
                                                        {link.name.toUpperCase()}
                                                    </Button>
                                                </Dialog.Close>
                                            ))}
                                        </div>
                                    )}
                                </Dialog.Content>
                            </motion.nav>
                        </Dialog.Portal>
                    ) : null}
                </AnimatePresence>
            </Dialog.Root>
        </div>
    );
};
