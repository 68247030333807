import { Link } from '@remix-run/react';
import { useTypedLoaderData } from 'remix-typedjson';
import { Button, type ButtonProps } from '~/cds/Button';
import { URLS } from '~/common/constants/urls';
import { cn } from '~/common/utils/styles';
import type { loader as landingLoader } from '~/routes/_app/route';
import { MobileMenu } from './components';

export type LinksType = {
    name: string;
    href: string;
    buttonVariant?: ButtonProps['variant'];
    target?: string;
};

export const NavLinks: LinksType[] = [
    { name: 'Home', href: URLS.PUBLIC.ROOT },
    // { name: 'Clubhouse', href: URLS.PUBLIC.CLUBHOUSE },
    {
        name: 'Contact',
        href: 'https://creatordaoapp.typeform.com/to/ekqt1T0H',
        target: '_blank',
    },
];

export const NavAuthLinks: LinksType[] = [
    { name: 'Login', href: URLS.PUBLIC.AUTH.LOGIN, buttonVariant: 'primary' },
    {
        name: 'Sign Up',
        href: URLS.PUBLIC.AUTH.SIGNUP,
        buttonVariant: 'tertiary',
    },
];

export const NavPortalLinks: LinksType[] = [
    { name: 'Member Portal', href: URLS.HOME, buttonVariant: 'tertiary' },
];

const AuthItems = ({ userId }: { userId: string | null }) => {
    const links: LinksType[] = userId ? NavPortalLinks : NavAuthLinks;
    return (
        <>
            {links.map((link) => (
                <Button
                    key={link.name}
                    variant={link.buttonVariant}
                    size="lg"
                    as={Link}
                    to={link.href}
                >
                    {link.name.toUpperCase()}
                </Button>
            ))}
        </>
    );
};

export const Navbar = () => {
    const { userId, flags } = useTypedLoaderData<typeof landingLoader>();
    const avalonOnboarding = flags['avalon_onboarding'];

    return (
        <header
            className={cn(
                'sticky top-0 isolate z-50 h-[88px] w-full items-center border-b border-gray-800 bg-black py-4 backdrop-blur-[12.5px]',
                'xs:flex xs:justify-between xs:px-6',
                'sm:grid sm:grid-cols-3 sm:px-10'
            )}
        >
            <Link to={URLS.PUBLIC.ROOT} className="justify-start">
                <img
                    src="/images/landing/cdao_landing_logo.svg"
                    alt="CreatorDAO Logo"
                    width="198"
                    height="18"
                />
            </Link>
            <div className="justify-center xs:hidden sm:flex">
                {NavLinks.map((link) =>
                    link.target ? (
                        <Button key={link.name} variant="primary" size="lg">
                            <a target={link.target} href={link.href}>
                                {link.name.toUpperCase()}
                            </a>
                        </Button>
                    ) : (
                        <Button
                            key={link.name}
                            variant="primary"
                            size="lg"
                            as={Link}
                            to={link.href}
                        >
                            {link.name.toUpperCase()}
                        </Button>
                    )
                )}
            </div>
            <div className="justify-end gap-4 xs:hidden sm:flex">
                {avalonOnboarding && <AuthItems userId={userId} />}
            </div>
            <MobileMenu userId={userId} avalonOnboarding={avalonOnboarding} />
        </header>
    );
};
