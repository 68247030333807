import * as React from 'react';
import { Label } from '~/cds/Label';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '~/common/components';
import { cn } from '~/common/utils/styles';

const NewsList = [
    {
        name: 'Fortune',
        logo: '/images/landing/news/fortune.png',
        content:
            'CreatorDAO raises $20 million in ‘ambitious bet’ on creator economy; a16z and Paris Hilton among backers',
        metadata: 'Fortune  |  Aug 9, 2022',
    },
    {
        name: 'Tubefilter',
        logo: '/images/landing/news/tubefilter.png',
        content:
            'After raising $20 million, a startup wants creators to “invest in each other”',
        metadata: 'Tubefilter  |  Aug 9, 2023',
    },
    {
        name: 'CoinDesk',
        logo: '/images/landing/news/coindesk.png',
        content: 'Andreessen Horowitz Leads $20M Funding Round for CreatorDAO',
        metadata: 'CoinDesk  |  Aug 9, 2023',
    },
    {
        name: 'LA Business Journal',
        logo: '/images/landing/news/labusinessjournal.png',
        content: 'CreatorDAO Raises $20M Seed Round to Invest in Creators',
        metadata: 'LA Business Journal  |  Aug 29, 2023',
    },
];

export const NewsTabs = ({ ringColor }: { ringColor?: string }) => {
    const [activeTab, setActiveTab] = React.useState<string>(NewsList[0].name);

    React.useEffect(() => {
        // change tab every 4s
        const interval = setInterval(() => {
            const currentIndex = NewsList.findIndex(
                (investor) => investor.name === activeTab
            );
            const nextIndex = (currentIndex + 1) % NewsList.length;
            setActiveTab(NewsList[nextIndex].name);
        }, 4000);
        return () => clearInterval(interval);
    }, [activeTab]);

    return (
        <div
            className={cn(
                'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform bg-white',
                'xs:w-[calc(100vw-7%)] xs:rounded-[40px]',
                'sm:w-[calc(100vw-20%)] sm:rounded-[50px]'
            )}
        >
            <Tabs
                value={activeTab}
                defaultValue={NewsList[0].name}
                className={cn(
                    'pointer-events-none bg-none',
                    'xs:min-h-[402px] xs:px-[30.87px] xs:py-[82px]',
                    'sm:min-h-[637.5px] sm:p-[120px]'
                )}
            >
                <TabsList className="flex items-center justify-center bg-transparent">
                    {NewsList.map((investor, index) => (
                        <TabsTrigger
                            key={index}
                            value={investor.name}
                            className="w-88 h-88 relative flex items-center rounded-full bg-white p-2"
                        >
                            {activeTab === investor.name && (
                                <svg
                                    width={40}
                                    height={40}
                                    className="absolute top-0 left-0 h-full w-full"
                                >
                                    <circle
                                        cx="50%"
                                        cy="50%"
                                        r="40%"
                                        stroke={ringColor || '#0E0E0D'}
                                        strokeWidth="5px"
                                        strokeDasharray="471"
                                        strokeDashoffset="471"
                                        fill="transparent"
                                        style={{
                                            transform: 'rotate(-90deg)',
                                            transformOrigin: 'center',
                                            animation:
                                                'clock-animation 4s linear infinite',
                                        }}
                                    />
                                </svg>
                            )}
                            <img
                                loading="eager"
                                src={investor.logo}
                                alt={investor.name}
                                width={64}
                                height={64}
                                className={cn(
                                    'rounded-full border-white',
                                    'xs:h-[34.26px] xs:w-[34.26px] xs:border-[2.14px]',
                                    'sm:h-16 sm:w-16 sm:border-[7px]'
                                )}
                            />
                        </TabsTrigger>
                    ))}
                </TabsList>
                {NewsList.map((investor, index) => (
                    <TabsContent
                        key={index}
                        value={investor.name}
                        className="border-0 p-0 xs:mt-[35px] sm:mt-[53.5px]"
                    >
                        <div
                            className={cn(
                                'flex flex-col items-center justify-center text-center',
                                'xs:space-y-8',
                                'sm:space-y-[100px]'
                            )}
                        >
                            <h1
                                className={cn(
                                    'text-center font-medium tracking-[-6%]',
                                    'xs:text-[18px] xs:leading-[24px]',
                                    'sm:text-[36px] sm:leading-[40px]'
                                )}
                            >
                                {investor.content}
                            </h1>
                            <Label
                                className={cn(
                                    'text-center tracking-[-2%] text-[#71736D]',
                                    'xs:text-[14px] xs:leading-[16px]',
                                    'sm:text-[20px] sm:leading-[28px]'
                                )}
                            >
                                {investor.metadata}
                            </Label>
                        </div>
                    </TabsContent>
                ))}
            </Tabs>
        </div>
    );
};
