import { Link } from '@remix-run/react';
import {
    AiFillYoutube,
    AiOutlineInstagram,
    AiOutlineTwitter,
} from 'react-icons/ai';
import { FaTiktok } from 'react-icons/fa';
import { Button } from '~/common/components/cds/Button';
import { Typography } from '~/common/components/cds/Typography';
import { URLS } from '~/common/constants/urls';
import { cn } from '~/common/utils/styles';

export type Section = {
    name: string;
    isBold: boolean;
    isAvailable: boolean;
    href?: string;
};
const FooterLinks: { [section: string]: Section[] } = {
    section2: [
        {
            name: 'Company',
            isBold: true,
            isAvailable: true,
        },
        {
            name: 'Careers',
            href: 'https://boards.greenhouse.io/creatordao',
            isBold: false,
            isAvailable: true,
        },
        {
            name: 'Blog',
            href: 'https://www.creatordao.com/blog',
            isBold: false,
            isAvailable: true,
        },
    ],
    section3: [
        {
            name: 'Legal',
            isBold: true,
            isAvailable: true,
        },
        {
            name: 'Terms & Conditions',
            href: URLS.PUBLIC.TERMS,
            isBold: false,
            isAvailable: true,
        },

        {
            name: 'Privacy Policy',
            href: URLS.PUBLIC.PRIVACY,
            isBold: false,
            isAvailable: true,
        },
    ],
};

const FooterSocialMediaLinks = [
    {
        name: 'TikTok',
        href: 'https://tiktok.com/@creatordao',
        icon: <FaTiktok className="text-lg" />,
    },
    {
        name: 'Instagram',
        href: 'https://instagram.com/joincreatordao',
        icon: <AiOutlineInstagram className="text-lg" />,
    },
    {
        name: 'Twitter',
        href: 'https://twitter.com/joincreatordao',
        icon: <AiOutlineTwitter className="text-lg" />,
    },
    {
        name: 'YouTube',
        href: 'https://www.youtube.com/@creatordao',
        icon: <AiFillYoutube className="text-lg" />,
    },
];

export const Footer = () => {
    return (
        <footer
            className={cn(
                'flex w-full flex-wrap justify-between overflow-hidden border-t border-gray-800 bg-black',
                'xs:flex-col xs:gap-y-8 xs:px-8 xs:pt-[65px] xs:pb-16',
                'sm:flex-row sm:px-20 sm:pt-20 sm:pb-[182px]'
            )}
        >
            <div className="mr-8 flex flex-col gap-8 xs:min-h-[116px] sm:min-h-[128px]">
                <Link
                    to={URLS.PUBLIC.ROOT}
                    className="justify-start"
                    prefetch="intent"
                >
                    <img
                        src="/images/landing/cdao_landing_logo.svg"
                        alt="CreatorDAO Logo"
                        className="h-[16px] w-[179.53px]"
                        height="16px"
                        width="179.53px"
                    />
                </Link>
                {/*TODO: This has been temporarily removed as we are not expecting email signups, currently*/}
                {/*<SubscribeInput />*/}
            </div>
            <div className="flex flex-wrap gap-y-8 xs:flex-col sm:flex-row sm:gap-x-16 2xl:gap-x-28">
                {Object.values(FooterLinks).map((section, index) => (
                    <div key={index} className="grid grid-rows-3 gap-y-4">
                        {section.map((item, index) =>
                            item.href ? (
                                <Link
                                    key={index}
                                    to={item.href}
                                    prefetch="intent"
                                >
                                    <Typography
                                        intent="body"
                                        size="md"
                                        className={cn(
                                            'cursor- w-full font-normal text-white',
                                            {
                                                'font-semibold': item.isBold,
                                                'cursor-default text-gray-600':
                                                    !item.isAvailable,
                                            }
                                        )}
                                    >
                                        {item.name}
                                    </Typography>
                                </Link>
                            ) : (
                                <Typography
                                    key={index}
                                    intent="body"
                                    size="md"
                                    className={cn(
                                        'w-full cursor-default font-normal text-white',
                                        {
                                            'font-semibold': item.isBold,
                                        }
                                    )}
                                >
                                    {item.name}
                                </Typography>
                            )
                        )}
                    </div>
                ))}
            </div>
            <div className="flex flex-col gap-9">
                <div className="flex gap-5">
                    {FooterSocialMediaLinks.map((item, index) => (
                        <Link
                            key={index}
                            className="inline-block"
                            to={item.href}
                            rel="noreferrer noopener"
                            aria-label={`CreatorDAO ${item.name}`}
                        >
                            <Button
                                aria-label={`CreatorDAO ${item.name}`}
                                variant="outlined"
                                iconOnly={item.icon}
                                className="bg-gray-50"
                            />
                        </Link>
                    ))}
                </div>
                <Typography
                    intent="body"
                    size="md"
                    className="font-normal text-white"
                >
                    © 2023 CreatorDAO
                </Typography>
            </div>
        </footer>
    );
};
